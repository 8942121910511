import "./scss/style.scss";
import 'bootstrap';
import { tns } from "../node_modules/tiny-slider/src/tiny-slider";
// import { spawnSync } from "child_process";

// $('header').load("header.html");
// $('footer').load("footer.html");
$('#some-services').load("block-some-services.html");

if ($.contains(document.body, document.getElementById('slider-main'))) {

    var slider_main = tns({
        container: '#slider-main',
        items: 1,
        loop: true,
        slideBy: 'page',
        autoplay: true,
        speed: 1000,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        controls: false,
        navPosition: 'bottom',
        lazyload: true,
    });

    // $(window).on("load resize scroll", function(e) {
    //     $('.block-banner-main .slider-main .items').each(function(index, element) {
    //         if ($(window).width() > 900) {
    //             if ($(window).scrollTop() > 30) {
    //                 var ratio = Math.round((($(window).scrollTop() - $(this).offset().top) / $(this).height()) * 100);
    //                 $(this).css('background-position', 'right ' + parseInt(-(ratio * 1.5)) + 'px');
    //             }
    //         } else {
    //             $(this).css('background-position', 'right top');
    //         }
    //     });
    // });
}

if ($.contains(document.body, document.getElementById('slider-carousel'))) {

    var slider_carousel = tns({
        container: '#slider-carousel',
        items: 1,
        slideBy: 'page',
        autoplay: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        controls: false,
        nav: false,
        mouseDrag: true
    });

    document.querySelector('.btn-left').onclick = function() {
        slider_carousel.goTo('prev');
    };

    document.querySelector('.btn-right').onclick = function() {
        slider_carousel.goTo('next');
    };
}

$('.custom-file-input').on('change', function(event) {
    var inputFile = event.currentTarget;
    $(inputFile).parent().find('.custom-file-label').html(inputFile.files[0].name);
});

//cabecera fija
$(document).ready(function() {
	var menu = $('header');
	var contenedor = $('header');
	var cont_offset = contenedor.offset();
	// Cada vez que se haga scroll en la pÃ¡gina
	// haremos un chequeo del estado del menÃº
	// y lo vamos a alternar entre 'fixed' y 'static'.

	$(window).on('scroll', function() {
		//alert($(window).scrollTop());
		if($(window).scrollTop() > 1) {
		menu.addClass('header-fixed');
		} else {
		menu.removeClass('header-fixed');
		}
	});
});

// scroll spy 
$(document).ready(function(){
    // Add scrollspy to <body>
    $('body').scrollspy({target: ".navbar", offset: 50});   
  
    // Add smooth scrolling on all links inside the navbar
    $("#myNavbar a").on('click', function(event) {
      // Make sure this.hash has a value before overriding default behavior
      if (this.hash !== "") {
        // Prevent default anchor click behavior
        event.preventDefault();
  
        // Store hash
        var hash = this.hash;
  
        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate({
          scrollTop: $(hash).offset().top
          
        }, 800, function(){
     
          // Add hash (#) to URL when done scrolling (default click behavior)
          window.location.hash = hash;
        });
      }  // End if
    });
  });

  $(document).ready(function(){
  new WOW().init();

  wow = new WOW(
  {
  boxClass:     'wow',      // default
  animateClass: 'animated', // default
  offset:       0,          // default
  mobile:       true,       // default
  live:         true        // default
  }
  )
  wow.init();
});